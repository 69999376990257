import React from 'react'

function SectionHeaning({subTitle,title,description}) {
  return (
    <div className="text-center  mb-16 mt-4">
    <p className="uppercase text-[#000000]">{subTitle}</p>
    <h2 className="text-3xl font-bold md:text-5xl">{title}</h2>
      <p className="mx-auto mt-4 max-w-lg text-[#647084]">
       {description}
      </p>
  </div>
  )
}

export default SectionHeaning