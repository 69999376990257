import { featuresData } from "@/data/features";
import Image from "next/image";
import SectionHeaning from "../uikit/SectionHeaning";

function Features() {
  return (
    <section>
      <div className="mx-auto max-w-7xl px-5 py-10 md:px-10 ">
        <div className="mx-auto w-full max-w-3xl text-center">
          <SectionHeaning
            title={"Empower Your Vision"}
            subTitle={"Elevate Your Designs"}
            description={
              "Discover a realm where creativity meets affordability with Youcad's Budget-Friendly Autodesk Solutions"
            }
          />
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-8 md:grid-cols-3 lg:gap-12">
          {featuresData.map((e, i) => (
            <div
              key={i}
              className="relative mb-8 flex flex-col rounded-2xl border border-solid border-black p-8 [box-shadow:#EC1C1C_9px_9px] lg:mb-4"
            >
              <div className="absolute -top-8 bottom-auto left-auto right-4 flex h-16 w-16 flex-col items-center justify-center rounded-full border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] lg:right-8">
                <Image
                  width={40}
                  height={40}
                  src={e.icon}
                  alt=""
                  className="relative z-10 inline-block h-8"
                />
                <div className="absolute z-0 h-9 w-9 rounded-full object-cover border border-[#c0d1ff] bg-[#ec1c1c7e]"></div>
              </div>
              <p className="mb-4 text-xl font-semibold">{e.title}</p>
              <p>
               {e.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Features;
