


export const featuresData = [
    {
        title:"Support",
        icon:"https://assets.website-files.com/63904f663019b0d8edf8d57c/639157f1a197859a6cd7f265_image%203.png",
        text:"Our dedicated support team is committed to assisting you every step of the way."
    },
    {
        title:"Organize",
        icon:"https://assets.website-files.com/63904f663019b0d8edf8d57c/63915859fa889834c4f1ff92_image%203-2.png",
        text:"Efficiency is key, and Youcad helps you streamline your workflow."
    },
    {
        title:"Flexibility",
        icon:"https://assets.website-files.com/63904f663019b0d8edf8d57c/639158510667812dff08e1af_image%203-4.png",
        text:"Our dedicated support team is committed to assisting you every step of the way."
    },
    {
        title:"Speed",
        icon:"https://assets.website-files.com/63904f663019b0d8edf8d57c/6391585b7b7cd87baef5e9ec_image%203-1.png",
        text:"Time is of the essence, and Youcad ensures that your projects move at the speed of innovation."
    },
    {
        title:"Quality",
        icon:"https://assets.website-files.com/63904f663019b0d8edf8d57c/639158557ac2b528531836f1_image%203-3.png",
        text:"Precision and quality are at the core of Youcad's.We believe in delivering excellence without compromise. "
    },
    {
        title:"Resources",
        icon:"https://assets.website-files.com/63904f663019b0d8edf8d57c/639157f3db4f4b8767c499ba_image%203-5.png",
        text:"We recognize the importance of having the right resources at your fingertips."
    },
]